const Contact = () => {


  return (
    <section id="contact" class="pt-32 pb-16">
  <div class="container mx-auto flex flex-col md:flex-row md:items-center gap-10">
    <div class="w-full md:w-1/2">
      <img src="../assets/contact-image.jpeg" alt="Contact" class="w-full h-auto rounded-lg shadow-md" />
    </div>

    <div class="w-full md:w-1/2">
      <h2 class="font-playfair text-5xl text-red mb-5">Get In Touch</h2>
      <a href="mailto:aristotlepippa@gmail.com">
        <button
          class="p-5 bg-yellow font-semibold text-deep-blue mt-5 hover:bg-red hover:text-white transition duration-500"
          type="button" /* Change type to "button" for accessibility */
        >
          SEND ME A MESSAGE
        </button>
      </a>
    </div>
  </div>
</section>

  );
};

export default Contact;

